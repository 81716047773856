import type { ApexOptions } from 'apexcharts';
import dynamic from 'next/dynamic';

// types
type KPIApexType =
  | 'line'
  | 'area'
  | 'bar'
  | 'pie'
  | 'donut'
  | 'radialBar'
  | 'scatter'
  | 'bubble'
  | 'heatmap'
  | 'candlestick'
  | 'boxPlot'
  | 'radar'
  | 'polarArea'
  | 'rangeBar'
  | 'rangeArea'
  | 'treemap';

const Chart = dynamic(() => import('react-apexcharts'), { ssr: false });

type ChartKpiProps = {
  series: ApexAxisChartSeries | ApexNonAxisChartSeries;
  options: ApexOptions;
  type: KPIApexType;
  style: {
    height?: string;
    width?: string;
  };
};

const ChartKpi = (props: ChartKpiProps) => {
  const { series, options, type, style } = props;

  //single value check with line chart
  let finalType = type;
  // if (
  //   type === 'line' &&
  //   typeof series[0] === 'object' &&
  //   series[0].data.length < 2
  // )
  //   finalType = 'scatter';

  return (
    <Chart
      options={options}
      series={series}
      type={finalType}
      width={style.width ?? '100%'}
      height={style.height ?? '100%'}
    />
  );
};

export default ChartKpi;
