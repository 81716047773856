import type { ApexOptions } from 'apexcharts';
import type { ReturnedRow } from 'database/bigQueryHelper';
import { numberToString } from 'lib/numberToString';
import type { KBOption } from '../../types/kpi-builder';
import type { ApexLegend } from './../../types/apex-common';
import resToDataSeries from './resToSeries';

type ApexParams = {
  labels: string[];
  colors?: string[];
  suffix?: string;
  prefix?: string;
  showTotal?: boolean;
  decimal?: number;
  lengthToWrap?: number;
  yAxisMaxWidth?: number;
  yAxisMinWidth?: number;
  hideGrid?: boolean;
  dataLabelsSize?: string;
  showRepartition?: boolean;
  data?: (ReturnedRow | Partial<KBOption>)[];
  secretStatText?: string;
  cancelAnimations?: boolean;
  hideYaxis?: boolean;
  customEvolRateField?: string;
  disableReduceNb?: boolean;
};

type EvolutionRateProps = {
  data: ReturnedRow[];
  positiveIcon?: string;
  negativeIcon?: string;
  neutralIcon?: string;
};

type FillType = 'solid' | 'gradient' | 'pattern' | 'image';
type ColorStop = {
  offset: number;
  color: string;
  opacity: number;
};

type DonutParams = {
  onClick?: (e: any) => void;
  legendPosition?: 'left' | 'right' | 'bottom' | 'top';
  legendHorizontalPos?: 'left' | 'center' | 'right';
  legendProps?: ApexLegend;
  totalToShow?: string;
  totalLabel?: string;
} & ApexParams;
type BarParams = {
  horizontal?: boolean;
  tickAmount?: number;
  data?: (ReturnedRow | Partial<KBOption>)[];
  onClick?: (e: any) => void;
  numberFormat?: boolean;
  showAbsolute?: boolean;
  hideLegend?: boolean;
  offsetX?: number;
  evolutionRate?: EvolutionRateProps;
  diverging?: boolean;
  legendPosition?: 'left' | 'right' | 'bottom' | 'top';
  legendHorizontalPos?: 'left' | 'center' | 'right';
  legendProps?: ApexLegend;
  total?: number | undefined;
  maxX?: number;
  minX?: number;
  maxY?: number;
  minY?: number;
  hideXaxis?: boolean;
  barHeight?: string;
  columnWidth?: string;
  lengthToWrap?: number;
  hideXAxisBorder?: boolean;
  withTransparentStroke?: boolean;
  customTooltipField?: string;
} & ApexParams;

type LineParams = {
  showEvolutionRate?: boolean;
  maxY?: number;
  minY?: number;
  checkDatalabelsOffset?: boolean;
  curveStraith?: boolean;
  lineWidth?: number;
  customTooltipContent?: {
    content: string;
    valField?: string;
    optField?: string;
    evolRate?: boolean;
    hideFirst?: boolean;
  };
  legendPosition?: 'left' | 'right' | 'bottom' | 'top';
  forceNiceScale?: boolean;
  markerSize?: number;
  dashArray?: number[];
  forecastDataPoints?: {
    count: number;
    fillOpacity: number;
    strokeWidth?: number;
    dashArray: number;
  };
  fill?: {
    colors?: string[];
    opacity?: number;
    type?: FillType | FillType[];
    gradient?: {
      shade?: 'dark' | 'light';
      gradientToColors?: string[];
      type?: 'horizontal' | 'vertical' | 'diagonal1' | 'diagonal2';
      shadeIntensity?: number;
      inverseColors?: boolean;
      stops?: number[];
      colorStops?: ColorStop[] | ColorStop[][];
    };
  };
  allDataLabels?: boolean;
} & ApexParams;

type RadarParams = {
  maxY?: number;
  minY?: number;
  tickAmount?: number;
} & ApexParams;

function testWhite(x: string) {
  const white = new RegExp(/^\s$/);
  return white.test(x.charAt(0));
}

function wordWrap(str: string, maxWidth: number) {
  try {
    const newLineStr = '\n';
    let res = '';
    while (str.length > maxWidth) {
      let found = false;
      for (let i = maxWidth - 1; i >= 0; i--) {
        if (testWhite(str.charAt(i))) {
          res = res + [str.slice(0, i), newLineStr].join('');
          str = str.slice(i + 1);
          found = true;
          break;
        }
      }
      if (!found) {
        res += [str.slice(0, maxWidth), newLineStr].join('');
        str = str.slice(maxWidth);
      }
    }

    return res + str;
  } catch (e) {
    return str;
  }
}

const options = {
  labels: ['Data A', 'Data B', 'Data C', 'Data D', 'Data E'],
  chart: {
    type: 'donut',
    fontFamily: 'inherit',
    toolbar: {
      show: false,
    },
  },
  noData: {
    text: 'Aucune donnée disponible...',
  },
  plotOptions: {
    pie: {
      donut: {
        size: '60%',
      },
    },
    bar: {
      columnWidth: '60',
      barHeight: '70',
      borderRadius: 6,
      borderRadiusApplication: 'end',
      dataLabels: {
        position: 'top',
        hideOverflowingLabels: true,
        orientation: 'horizontal',
      },
    },
  },
  legend: {
    fontFamily: 'inherit',
    fontSize: '10',
    position: 'left',
    offsetY: 0,
    offsetX: 0,
    height: undefined,
    width: 160,
    markers: {
      width: 12,
      height: 12,
      strokeColor: '#fff',
      radius: 3,
    },
    itemMargin: {
      horizontal: 4,
      vertical: 4,
    },
    labels: {
      colors: ['#346686'],
    },
  },
  dataLabels: {
    enabled: true,
    style: {
      fontFamily: 'inherit',
      fontSize: '11',
      fontWeight: 'bold',
    },
    formatter: function (val: string | number | number[]) {
      return (typeof val === 'number' ? val.toFixed(0) : val) + '%';
    },
    dropShadow: {
      enabled: false,
      top: 1,
      left: 1,
      blur: 1,
      color: '#000',
      opacity: 0,
    },
  },
  colors: ['#ea580c', '#f97316', '#fb923c', '#fdba74'],
  tooltip: {
    style: {
      fontFamily: 'inherit',
    },
    y: {
      formatter: function (val: string | number | number[]) {
        if (val === undefined) return '/';
        return numberToString(val as number);
      },
    },
  },
  responsive: [
    {
      breakpoint: 1400,
      options: {
        title: {
          style: {
            fontSize: 12,
          },
        },
      },
    },
    {
      breakpoint: 480,
      options: {
        // chart: {
        //   width: 200,
        // },
        legend: {
          position: 'bottom',
        },
      },
    },
  ],
  states: {
    hover: {
      filter: {
        type: 'none',
      },
    },
  },
  xaxis: {
    type: 'category',
    labels: {
      show: true,
      style: {
        fontFamily: 'inherit',
        fontSize: '10',
      },
    },
  },
  yaxis: {
    show: true,
    labels: {
      style: {
        fontFamily: 'inherit',
        fontSize: '10',
      },
    },
  },
  grid: {
    show: true,
    row: {
      colors: ['#FAF9F6', 'transparent'], // takes an array which will be repeated on columns
      opacity: 0.8,
    },
  },
} as ApexOptions;

export function donutOptions(props: DonutParams): ApexOptions {
  return {
    ...options,
    plotOptions: {
      ...options.plotOptions,
      pie: {
        ...options.plotOptions?.pie,
        donut: {
          size: '60%',
          labels: {
            show: props.showTotal || false,
            total: {
              showAlways: true,
              show: true,
              label: props.totalLabel || '',
              fontSize: '10',
              fontFamily: 'inherit',
              formatter: function (w: any) {
                return props.totalToShow
                  ? props.totalToShow
                  : numberToString(
                      w.globals.seriesTotals.reduce((a: any, b: any) => {
                        return a + b;
                      }, 0),
                      true
                    );
              },
            },
          },
        },
      },
    },
    labels: props.labels,
    colors: props.colors ?? options.colors,
    chart: {
      ...options.chart,
      type: 'donut',
      events: {
        dataPointSelection: function (
          event: any,
          chartContext: any,
          config: any
        ) {
          if (props.onClick && event && chartContext) {
            // props.onClick(config.dataPointIndex);
            const index = config.dataPointIndex!;
            const label = props.labels[index]!;
            const value = props.data?.find((obj) =>
              Object.values(obj).includes(label)
            );
            props.onClick(value);
          }
        },
        dataPointMouseEnter: function (event: any) {
          if (props.onClick) {
            event.target.style.cursor = 'pointer';
          }
        },
      },
    },
    legend: {
      ...options.legend,
      ...(props.legendProps || {}),
      horizontalAlign: props.legendHorizontalPos || 'left',
      position: props.legendPosition || 'left',
    },
    tooltip: {
      ...options.tooltip,
      y: {
        formatter: function (val: string | number | number[], opts: any) {
          if (props.showRepartition) {
            const sum = opts.config.series.reduce(
              (a: number, b: number) => a + b,
              0
            );
            const percent = ((val as number) / sum) * 100;
            return `${numberToString(val as number, true, props.decimal)}${
              props.suffix || ' '
            }(${percent.toFixed(0)}%)`;
          }
          return (
            numberToString(val as number, true, props.decimal) +
            (props.suffix || '')
          );
        },
        title: {
          formatter: (seriesName) => {
            return `${seriesName} :`;
          },
        },
      },
    },
    dataLabels: {
      ...options.dataLabels,
      style: {
        fontFamily: 'inherit',
        fontSize: props.dataLabelsSize || '11',
        fontWeight: 'bold',
      },
    },
  };
}

export function barOptions(props: BarParams): ApexOptions {
  const { barHeight, columnWidth } = props;
  return {
    ...options,
    labels: props.labels || options.labels,
    colors: props.colors || options.colors,
    chart: {
      ...options.chart,
      animations: {
        enabled: props.cancelAnimations ? false : true,
      },
      type: 'bar',
      stacked: props.diverging || false,
      events: {
        dataPointSelection: function (
          event: any,
          chartContext: any,
          config: any
        ) {
          if (props.onClick && event && chartContext) {
            // props.onClick(config.dataPointIndex);
            const index = config.dataPointIndex!;
            const label = props.labels[index]!;
            const value = props.data?.find((obj) =>
              Object.values(obj).includes(label)
            );
            props.onClick(value);
          }
        },
        dataPointMouseEnter: function (event: any) {
          if (props.onClick) {
            event.target.style.cursor = 'pointer';
          }
        },
      },
    },
    tooltip: {
      ...options.tooltip,
      followCursor: true,
      x: {
        show: true,
        formatter: function (val: string | number | number[]) {
          return val + '';
        },
      },
      y: {
        title: {
          formatter: function () {
            return '';
          },
        },
        formatter: function (
          val: number,
          { dataPointIndex, w, seriesIndex }: any
        ) {
          let sum = w?.globals?.series[seriesIndex]?.reduce(
            (a: number, b: number) => a + b,
            0
          );
          if (props.diverging) {
            sum = Math.abs(sum);
            val = Math.abs(val);
            if (props.customTooltipField) {
              const series = resToDataSeries(
                {
                  title: '',
                  props: {
                    table: '',
                    dimensions: [],
                  },
                  returnedRows: props.data?.map((row: ReturnedRow) => ({
                    ...row,
                    nb: row[props.customTooltipField as string] as number,
                  })),
                },
                'diverging'
              ) as ApexAxisChartSeries;
              return (
                numberToString(
                  Math.abs(
                    (series[seriesIndex]?.data[dataPointIndex] as number) ?? 0
                  ),
                  true,
                  props.decimal || 0
                ) + (props.suffix || '')
              );
            }
          }
          let percent: string | number = (val / (props.total || sum)) * 100;
          percent =
            percent > 0 && percent < 1
              ? percent.toFixed(1).replace(/\.[0]+$/, '')
              : percent.toFixed(0);
          if (props.evolutionRate) {
            const evoltionRate: number | undefined =
              (props.evolutionRate.data[dataPointIndex]
                ?.evolution_rate as number) || 0;
            if (evoltionRate !== undefined) {
              return `${numberToString(val, true)} soit ${percent}% du total (${
                evoltionRate > 0 ? '+' : ''
              }${evoltionRate.toFixed(0)}% vs. l'année précédente)`;
            }
          }
          if (props.showRepartition) {
            return `${numberToString(
              val,
              true,
              props.decimal || 0
            )} (${percent}%)`;
          }
          if (props.showAbsolute && props.data?.[dataPointIndex]) {
            return `${numberToString(
              (props.data[dataPointIndex] as ReturnedRow).absolute as number
            )} (${
              numberToString(val, true, props.decimal || 0) +
              (props.suffix || '')
            })`;
          }
          const repartition = props.total
            ? ` soit ${percent}% sur le total`
            : '';
          return (
            numberToString(val, true, props.decimal || 0) +
            (props.suffix || '') +
            repartition
          );
        },
      },
    },
    xaxis: {
      ...options.xaxis,
      categories: props.labels,
      axisBorder: {
        show: !props.hideXAxisBorder,
        color: '#C8C8C8',
        strokeWidth: 1,
        offsetX: 0,
        offsetY: 0,
      },
      axisTicks: {
        show: props.horizontal ? false : true,
      },
      labels: {
        ...options.xaxis?.labels,
        show: props.horizontal ? false : true,
        formatter: function (value: string): string | string[] {
          const newStr = wordWrap(value, 15);
          const newArray = newStr.split('\n');
          if (newArray.length > 1) {
            return [
              newArray[0]!,
              newArray.length > 2 ? newArray[1]! + '...' : newArray[1]!,
            ];
          }
          return newArray;
        },
      },
      max: props.horizontal
        ? props.maxX
          ? props.maxX
          : Math.max(...(props.data?.map((row) => row.nb) as number[])) * 1.4
        : undefined,
      min:
        props.horizontal && props.minX === 0
          ? props.minX
          : (props.minX ?? undefined),
    },
    yaxis: {
      show: props.horizontal ? true : false,
      tickAmount: props.tickAmount ?? 5,
      labels: {
        style: {
          fontFamily: 'inherit',
          fontSize: '10',
        },
        maxWidth: props.yAxisMaxWidth ?? 160,
        minWidth:
          props.yAxisMinWidth === 0
            ? props.yAxisMinWidth
            : (props.yAxisMinWidth ?? undefined),
        formatter: function (value: number): string | string[] {
          try {
            const newStr = wordWrap(
              value?.toString(),
              props.lengthToWrap ?? 15
            );
            let newArray = newStr.split('\n');
            // spot the "," columns and filter them
            newArray = newArray.filter((str) => str !== ',');
            // newArray = newArray.map((str, idx) => (idx < newArray.length - 1 ? str + ',' : str));

            if (newArray.length > 1) {
              if (props.lengthToWrap) {
                // opco2i condition
                const maxElements = 3;
                const tail = newArray.length > maxElements ? '...' : '';
                newArray = newArray.slice(0, maxElements);
                tail.length > 0 &&
                  (newArray[maxElements - 1] =
                    newArray[maxElements - 1] + tail);

                return newArray;
              }
              return [
                newArray[0]!,
                newArray.length > 2 ? newArray[1]! + '...' : newArray[1]!,
              ];
            }
            return newArray;
          } catch (e) {
            // console.log(e);
            return value?.toString();
          }
        },
      },
      max:
        (props.maxY ?? props.horizontal)
          ? undefined
          : Math.max(...(props.data?.map((row) => row.nb) as number[])) * 1.4,
      min: props.diverging
        ? Math.max(...(props.data?.map((row) => row.nb) as number[])) * -1.4
        : undefined,
    },
    dataLabels: {
      ...options.dataLabels,
      textAnchor: props.horizontal ? 'start' : 'middle',
      offsetY: props.secretStatText ? -5 : props.horizontal ? 0 : -20,
      offsetX: props.secretStatText
        ? 25
        : props.horizontal
          ? props.evolutionRate
            ? 30
            : 35
          : 0,
      style: {
        fontFamily: 'inherit',
        fontSize: props.dataLabelsSize || '11',
        fontWeight: props.secretStatText ? 'normal' : 'bold',
        colors: props.secretStatText
          ? ['#525252']
          : props.colors
            ? [...props.colors]
            : ['#000000'],
      },
      formatter: function (val: string | number | number[], options: any) {
        if (props.secretStatText) return props.secretStatText;

        const value: number =
          typeof val !== 'string'
            ? typeof val === 'number'
              ? val
              : ((val?.[0] ?? 0) as number)
            : Number(val)!;
        if (props.numberFormat) {
          return (
            (props.prefix || '') +
            numberToString(value, true, props.decimal || 0) +
            (props.suffix || '')
          );
        } else {
          let percent =
            (value * 100) /
            (props.total ||
              options.w.globals.seriesTotals[options.seriesIndex]);
          percent = Number.isNaN(percent) ? 0 : percent;
          if (props.evolutionRate) {
            const evolutionRate =
              (props.evolutionRate.data[options.dataPointIndex]
                ?.evolution_rate as number) || 0;
            const icon =
              evolutionRate > 0
                ? props.evolutionRate.positiveIcon
                : evolutionRate === 0
                  ? props.evolutionRate.neutralIcon
                  : props.evolutionRate.negativeIcon || '';
            return (
              numberToString(value, true, props.decimal || 0) + (icon || '')
            );
          }
          if (props.diverging) return percent.toFixed(0).replace('-', '') + '%';
          return (
            (percent > 0 && percent < 1
              ? percent.toFixed(1).replace(/\.[0]+$/, '')
              : percent.toFixed(0)) + '%'
          );
        }
      },
    },
    plotOptions: {
      bar: {
        ...options.plotOptions?.bar,
        dataLabels: {
          ...options.plotOptions?.bar?.dataLabels,
          hideOverflowingLabels: props.diverging ? false : true,
        },
        horizontal: props.horizontal || false,
        barHeight: barHeight || props.horizontal ? '70%' : '50%',
        columnWidth: columnWidth || '70%',
        // borderRadiusApplication: props.diverging ? 'around' : 'end',
        borderRadiusWhenStacked: props.diverging ? 'all' : 'last',
      },
    },
    stroke: {
      width: 2,
      colors: ['transparent'],
      show: props.withTransparentStroke ? true : false,
    },
    grid: {
      ...options.grid,
      show: props.hideGrid ? false : true,
      row: {
        colors: ['#FAF9F6', 'transparent'], // takes an array which will be repeated on columns
        opacity: props.hideGrid ? 0 : 0.8,
      },
    },
    legend: {
      ...options.legend,
      // show: props.diverging ? false : true,
      ...(props.legendProps || {}),
      horizontalAlign: props.legendHorizontalPos || 'left',
      position: props.legendPosition || 'left',
      inverseOrder: props.diverging ? true : false,
      show: !props.hideLegend,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          yaxis: {
            labels: {
              maxWidth: props.yAxisMaxWidth ? props.yAxisMaxWidth * 0.5 : 160,
              minWidth: props.yAxisMinWidth
                ? props.yAxisMinWidth * 0.5
                : undefined,
            },
          },
          dataLabels: {
            textAnchor: 'start',
            // dropShadow: {
            //   enabled: true,
            //   top: 1,
            //   left: 1,
            //   blur: 0,
            //   color: '#000',
            //   opacity: 0.85,
            // },
          },
        },
      },
    ],
  };
}

function checkMaxValue(data: ReturnedRow[], max: number) {
  const values = data.map((row) => row.nb as number);
  const maxValue = Math.max(...values);
  return maxValue > max / 2 ? 8 : -8;
}

function checkLastPostionInSerie(serie: number[]) {
  // check what is end of serie, length or  first null value in array
  let lastPosition = serie.length - 1;
  for (let i = 0; i < serie.length; i++) {
    if (serie[i] === null) {
      lastPosition = i - 1;
      break;
    }
  }
  return lastPosition;
}

export function lineOptions(props: LineParams): ApexOptions {
  return {
    ...options,
    labels: props.labels || options.labels,
    colors: props.colors || options.colors,
    forecastDataPoints: props.forecastDataPoints ?? {
      count: 0,
    },
    stroke: {
      curve: props.curveStraith ? 'straight' : 'smooth',
      width: props.lineWidth || 2,
      dashArray: props.dashArray,
    },
    fill: props.fill ?? {
      type: 'solid',
    },
    legend: {
      fontFamily: 'inherit',
      fontSize: '10',
      position: props.legendPosition || 'bottom',
      horizontalAlign: 'center',
    },
    // markers: {
    //   size: 4,
    //   strokeWidth: 2,
    // },
    dataLabels: {
      ...options.dataLabels,
      style: {
        fontFamily: 'inherit',
        fontSize: props.dataLabelsSize || '11',
        fontWeight: 'bold',
      },
      enabled: true,
      offsetY:
        props.checkDatalabelsOffset && props.data && props.maxY
          ? checkMaxValue(props.data as ReturnedRow[], props.maxY || 0)
          : -8,
      background: {
        enabled: false,
      },
      formatter: function (val: string | number, options: any) {
        // const labels = props.labels || options.labels || ['Autres'];
        if (props.allDataLabels)
          return typeof val === 'number'
            ? numberToString(
                val,
                props.disableReduceNb ? false : true,
                props.decimal || 0
              ) + (props.suffix || '')
            : (val || '') + (props.suffix || '');
        const serieLength =
          checkLastPostionInSerie(
            options?.w?.globals?.series?.[options?.seriesIndex]
          ) || 0;
        return options?.dataPointIndex === 0 ||
          options?.dataPointIndex === serieLength
          ? typeof val === 'number'
            ? numberToString(
                val,
                props.disableReduceNb ? false : true,
                props.decimal || 0
              ) + (props.suffix || '')
            : (val || '') + (props.suffix || '')
          : '';
      },
    },
    markers: {
      size: props.markerSize ?? 0,
    },
    tooltip: {
      ...options.tooltip,
      followCursor: true,
      x: {
        show: true,
        formatter: function (val: string | number | number[]) {
          return val + '';
        },
      },
      y: {
        formatter: function (val: string | number | number[], opts?: any) {
          if (val === undefined) return '/';
          if (props.showEvolutionRate) {
            if (props.customEvolRateField) {
              const serieName =
                opts?.w?.globals?.seriesNames[opts?.seriesIndex];
              const serie = props.data?.filter(
                (row) => row.agg1_label === serieName
              )?.[opts?.dataPointIndex] as ReturnedRow;
              let evolutionRate = serie[
                `${props.customEvolRateField}`
              ] as number;
              if (opts?.w?.globals?.seriesNames?.length === 1) {
                const data = props.data?.[opts?.dataPointIndex] as ReturnedRow;
                evolutionRate = data[`${props.customEvolRateField}`] as number;
              }
              return (
                numberToString(val as number, true, props.decimal || 0) +
                (evolutionRate
                  ? " (taux d'évolution vs. période précédente " +
                    (evolutionRate > 0 ? '+' : '') +
                    evolutionRate.toFixed(0) +
                    '%)'
                  : (props.suffix ?? ''))
              );
            }
            // get evolution rate between two points
            const series = opts?.series[opts?.seriesIndex] || [];
            const evolutionRate =
              opts?.dataPointIndex === 0
                ? null
                : ((series[opts?.dataPointIndex] -
                    series[opts?.dataPointIndex - 1]) /
                    Math.abs(series[opts?.dataPointIndex - 1])) *
                  100;
            return (
              numberToString(val as number, true, props.decimal || 0) +
              (evolutionRate !== null
                ? " (taux d'évolution vs. année précédente " +
                  (evolutionRate > 0 ? '+' : '') +
                  evolutionRate.toFixed(
                    evolutionRate < 1 && evolutionRate > -1 ? 1 : 0
                  ) +
                  '%)'
                : (props.suffix ?? ''))
            );
          }
          if (props.customTooltipContent) {
            if (
              props.customTooltipContent.hideFirst &&
              opts?.dataPointIndex === 0
            )
              return (
                numberToString(val as number, true, props.decimal || 0) +
                (props.suffix || '')
              );
            // custom text for tooltip
            const serieName: string =
              opts?.w?.globals?.seriesNames[opts?.seriesIndex];
            const serie = (
              serieName === 'Total'
                ? props.data
                : props.data?.filter((row) => row.agg1_label === serieName)
            )?.[opts?.dataPointIndex] as ReturnedRow; // get specific value object from data
            let content = props.customTooltipContent.content; // init text
            if (props.customTooltipContent.valField)
              // replace ${val} by value
              content = content.replace(
                '${val}',
                numberToString(
                  serie[`${props.customTooltipContent.valField}`] as number,
                  true,
                  props.decimal || 0
                )
              );
            else
              content = content.replace(
                '${val}',
                numberToString(val as number, true, props.decimal || 0)
              ); // replace ${val} by default series value
            if (props.customTooltipContent.optField)
              // replace ${opt} by optional value such as repartition
              content = content.replace(
                '${opt}',
                serie[`${props.customTooltipContent.optField}`] as string
              );
            if (
              props.customTooltipContent.evolRate &&
              opts?.dataPointIndex > 0
            ) {
              // add evolution rate with ${val} field
              const prevSerie = props.data?.filter(
                (row) => row.agg1_label === serieName
              )?.[opts?.dataPointIndex - 1] as ReturnedRow;
              const evolutionRate =
                ((serie[`${props.customTooltipContent.valField}`] as number) -
                  (prevSerie[
                    `${props.customTooltipContent.valField}`
                  ] as number)) /
                  Math.abs(
                    serie[`${props.customTooltipContent.valField}`] as number
                  ) || null;
              content =
                evolutionRate !== null
                  ? content.concat(
                      ` (évolution vs. période précédente ${numberToString(
                        evolutionRate * 100,
                        true,
                        props.decimal || 0
                      )}%)`
                    )
                  : content;
            }
            return content;
          }
          return numberToString(val as number) + (props.suffix || '');
        },
      },
    },
    xaxis: {
      ...options.xaxis,
      categories: props.labels || options.labels,
      tickPlacement: 'between',
    },
    yaxis: {
      ...options.yaxis,
      tickAmount: 4,
      forceNiceScale: props.forceNiceScale ?? true,
      max:
        props.maxY ||
        (Math.ceil(
          Math.max(
            ...(props.data?.map((row) => (row.nb as number) || 0) || [100])
          )
        ) < 100
          ? Math.ceil(
              Math.max(
                ...(props.data?.map((row) => (row.nb as number) || 0) || [100])
              ) * 1.2
            )
          : undefined),
      min:
        props.minY === 0
          ? props.minY
          : props.minY ||
            (Math.ceil(
              Math.max(
                ...(props.data?.map((row) => (row.nb as number) || 0) || [100])
              )
            ) < 100
              ? Math.floor(
                  Math.min(
                    ...(props.data?.map((row) => (row.nb as number) || 0) || [
                      100,
                    ])
                  ) -
                    Math.abs(
                      Math.min(
                        ...(props.data?.map(
                          (row) => (row.nb as number) || 0
                        ) || [100])
                      ) * 0.8
                    )
                )
              : undefined),
      labels: {
        show: props.hideYaxis ? false : true,
        maxWidth: props.yAxisMaxWidth || 160,
        minWidth: props.yAxisMinWidth || 0,
        style: {
          fontFamily: 'inherit',
          fontSize: '10',
        },
        formatter: function (val: number, opts: any) {
          return opts % 2 === 0
            ? numberToString(
                val,
                props.disableReduceNb ? false : true,
                props.decimal || 0
              ) + (props.suffix || '')
            : '';
        },
      },
    },
    chart: {
      ...options.chart,
      animations: {
        enabled: props.cancelAnimations ? false : true,
      },
    },
  };
}

function isSecretStat(data: number[][], idx: number) {
  let check = true;
  data.forEach((row) => {
    if (row[idx] !== 0) {
      check = false;
      return;
    }
  });

  return check;
}

export function bar100Options(props: BarParams): ApexOptions {
  return {
    ...options,
    labels: props.labels || options.labels,
    colors: props.colors || options.colors,
    chart: {
      type: 'bar',
      stacked: true,
      stackType: '100%',
      ...options.chart,
      animations: {
        enabled: props.cancelAnimations ? false : true,
      },
    },
    legend: {
      fontFamily: 'inherit',
      fontSize: '10',
      position: props.legendPosition || 'bottom',
      horizontalAlign: 'center',
      show: !props.hideLegend,
    },
    plotOptions: {
      ...options.plotOptions,
      bar: {
        ...options.plotOptions?.bar,
        horizontal: props.horizontal ?? true,
        barHeight: props.horizontal ? '70%' : '50%',
      },
    },
    dataLabels: {
      ...options.dataLabels,
      enabled: true,
      textAnchor: props.secretStatText ? 'start' : 'end',
      style: {
        fontFamily: 'inherit',
        fontSize: '11',
        fontWeight: props.secretStatText ? 'normal' : 'bold',
        colors: [
          function (opts: any) {
            // console.log({ opts });
            return isSecretStat(opts.series, opts.dataPointIndex)
              ? '#525252'
              : '#ffffff';
          },
        ],
      },
      offsetX: props.secretStatText ? 20 : -20,
      formatter: function (val, { dataPointIndex, w }) {
        return isSecretStat(w.globals.series, dataPointIndex)
          ? props.secretStatText || 'Aucune donnée disponible'
          : typeof val === 'number'
            ? val < 10
              ? ''
              : val.toFixed(0) + '%'
            : val + '%';
      },
    },
    tooltip: {
      style: {
        fontFamily: 'inherit',
      },
      y: {
        formatter: function (val: number, opts?: any): string {
          if (val === undefined) return '/';
          const sum = opts?.series
            ?.map((row: number[]) => row[opts?.dataPointIndex])
            ?.reduce((a: number, b: number) => a + b, 0);
          const percent = (val / sum) * 100;
          return numberToString(val) + ' (' + percent.toFixed(0) + '%)';
        },
      },
    },
    yaxis: {
      ...options.yaxis,
      labels: {
        style: {
          fontFamily: 'inherit',
          fontSize: '10',
        },
        maxWidth: props.yAxisMaxWidth ?? 160,
        minWidth:
          props.yAxisMinWidth === 0
            ? props.yAxisMinWidth
            : (props.yAxisMinWidth ?? undefined),
        formatter: function (value: number) {
          if (!value) return '';
          const newStr = wordWrap(value?.toString(), props.lengthToWrap ?? 15);
          let newArray = newStr.split('\n');

          // spot the "," columns and filter them
          newArray = newArray.filter((str) => str !== ',');
          // newArray = newArray.map((str, idx) => (idx < newArray.length - 1 ? str + ',' : str));

          if (newArray.length > 1) {
            if (props.lengthToWrap) {
              // opco2i condition
              const maxElements = 3;
              const tail = newArray.length > maxElements ? '...' : '';
              newArray = newArray.slice(0, maxElements);
              tail.length > 0 &&
                (newArray[maxElements - 1] = newArray[maxElements - 1] + tail);

              return newArray;
            }
            return [
              newArray[0]!,
              newArray.length > 2 ? newArray[1]! + '...' : newArray[1]!,
            ];
          }
          return newArray;
        },
      },
    },
    xaxis: {
      ...options.xaxis,
      labels: {
        ...options.xaxis?.labels,
        show: props.hideXaxis ? false : true,
        style: {
          fontFamily: 'inherit',
          fontSize: '10',
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          yaxis: {
            labels: {
              maxWidth: props.yAxisMaxWidth ? props.yAxisMaxWidth * 0.5 : 160,
              minWidth: props.yAxisMinWidth
                ? props.yAxisMinWidth * 0.5
                : undefined,
            },
          },
          dataLabels: {
            formatter: function (
              val: string | number | number[],
              { dataPointIndex, w }: any
            ) {
              return isSecretStat(w.globals.series, dataPointIndex)
                ? props.secretStatText || 'Aucune donnée disponible'
                : typeof val === 'number'
                  ? val < 40
                    ? ''
                    : val.toFixed(0) + '%'
                  : val + '%';
            },
          },
        },
      },
    ],
  };
}

export function radarOptions(props: RadarParams): ApexOptions {
  return {
    ...options,
    labels: props.labels || options.labels,
    colors: props.colors || options.colors,
    chart: {
      ...options.chart,
      type: 'radar',
    },
    plotOptions: {
      radar: {
        size: undefined,
        offsetX: 0,
        offsetY: 0,
        polygons: {
          strokeColors: '#e8e8e8',
          fill: {
            colors: ['transparent'],
          },
        },
      },
    },
    xaxis: {
      ...options.xaxis,
      labels: {
        show: true,
        formatter: function (value) {
          const newStr = wordWrap(value, 25);
          const newArray = newStr.split('\n');
          if (newArray.length > 1) {
            return [...newArray];
          } else return newArray;
        },
      },
    },
    yaxis: {
      ...options.yaxis,
      min: props.minY === 0 ? props.minY : props.minY || undefined,
      max: props.maxY === 0 ? props.maxY : props.maxY || undefined,
      tickAmount: props.tickAmount || 5,
      labels: {
        formatter: (value) => {
          return value === 0
            ? ''
            : numberToString(value, true, props.decimal || 0) +
                (props.suffix || '');
        },
        offsetX: 20,
      },
    },
    tooltip: {
      style: {
        fontFamily: 'inherit',
      },
      y: {
        formatter: function (val: string | number | number[]) {
          if (val === undefined) return '/';
          return (
            numberToString(val as number, true, props.decimal || 0) +
            (props.suffix || '')
          );
        },
      },
    },
    dataLabels: {
      ...options.dataLabels,
      enabled: false,
      formatter: function (value: number) {
        return (
          numberToString(value, true, props.decimal || 0) + (props.suffix || '')
        );
      },
      background: {
        enabled: true,
        borderRadius: 5,
        borderColor: '#fff',
      },
    },
    grid: {
      show: false,
    },
  };
}
