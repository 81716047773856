import { Fragment, useEffect, useState } from 'react';

import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

// types
import type { IdLabelObject } from '@/types';
import { CheckIcon } from '@heroicons/react/24/outline';
import { sortBy } from 'lodash';

type SelectProps = {
  options: IdLabelObject[] | null | undefined;
  widthTailwind?: string;
  title?: string;
  coloredInput?: boolean; // whether the select value is colored or not
  inputColor?: string; // color of the select value
  defaultValue?: IdLabelObject;
  listFit?: boolean;
  selectedVal?: IdLabelObject;
  extraOption?: IdLabelObject;
  disabled?: boolean;
  clickable?: boolean;
  ringWhenDisabled?: boolean;
  noSort?: boolean;
  placeholder?: string;
  setValue?: (value: Required<IdLabelObject>) => void;
  asyncSetValue?: (value: Required<IdLabelObject>) => Promise<void>;
};

export default function Select({
  options,
  widthTailwind,
  title,
  coloredInput,
  inputColor,
  defaultValue,
  listFit,
  selectedVal,
  extraOption,
  disabled,
  clickable,
  ringWhenDisabled,
  noSort,
  placeholder,
  setValue,
  asyncSetValue,
}: SelectProps) {
  const [selected, setSelected] = useState<IdLabelObject>(
    extraOption ?? defaultValue ?? { id: '', label: '' }
  );

  useEffect(() => {
    if (selectedVal) setSelected(selectedVal);
    else setSelected(defaultValue ?? { id: '', label: '' });
  }, [selectedVal, defaultValue]);

  const shiftAndReturn = (arr: IdLabelObject[], value?: IdLabelObject) => {
    if (!value) return arr;

    return [value, ...arr];
  };

  return (
    <Listbox
      disabled={disabled ?? clickable === false}
      value={{ ...selected }}
      onChange={setValue ?? asyncSetValue ?? setSelected}
    >
      <div className={`relative ${widthTailwind ?? 'w-56'}`}>
        {title ? <p className="font-semibold text-[10px] pl-3 mb-1">{title}</p> : null}
        <Listbox.Button
          className={`relative w-full min-h-[36px] cursor-default rounded-lg ${
            inputColor ?? 'bg-neutral-50'
          } py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm ${
            ringWhenDisabled ? 'disabled:border-[1px] disabled:border-red-400' : ''
          }`}
        >
          <span
            className={`block truncate font-medium text-[11px] ${
              coloredInput ? (disabled ? 'text-gray-400' : 'text-gray-500') : ''
            }`}
          >
            {disabled ? 'Indisponible' : placeholder ?? selectedVal?.label ?? selected.label}
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options
            className={`absolute z-10 mt-1 max-h-60 ${
              listFit ? 'w-fit' : 'w-full'
            } overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`}
          >
            {shiftAndReturn(
              noSort ? options ?? [] : sortBy(options ?? [], 'label'),
              extraOption
            ).map((option, index) => (
              <Listbox.Option
                key={index}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                    active ? 'bg-indigo-100 text-indigo-900' : 'text-gray-900'
                  }`
                }
                value={option}
              >
                {({ active }) => {
                  const compare = option.id === selected.id;
                  return (
                    <>
                      <span
                        className={`block whitespace-pre-wrap truncate ${
                          compare ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        {option.label}
                      </span>
                      {compare ? (
                        <span
                          className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                            active ? 'text-white' : 'text-emerald-500'
                          }`}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  );
                }}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}
