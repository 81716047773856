import { groupBy } from 'database/generics';
import { mapValues, round } from 'lodash';
import type { Query } from '../../database/bigQueryHelper';

/**
 * Impute missing values with a given value
 * @param query
 * @param imputation
 */
function impute(query: Query, imputation: number | string) {
  // get the available agg1 values
  const _agg1vls = query.returnedRows?.map((row) => ({
    id: row.agg1_id,
    label: row.agg1_label,
  }));

  // excepted value in array: nb, date, agg1_label
  const grouped = groupBy(query.returnedRows ?? [], { key: 'annee' });

  // period composed: { annee: 2012, period: "S1" }
  // period not composed: { annee: 2012 }
  const isPeriodComposed = query.returnedRows
    ? (query.returnedRows[0] ?? { period: undefined }).period
    : undefined;
  if (!isPeriodComposed && query.returnedRows?.length) {
    mapValues(grouped, (gv) => {
      if (!gv.length) return [];

      if (gv.length === (_agg1vls ?? []).length) {
        return gv;
      } else {
        // missing elements
        _agg1vls?.forEach((agg1) => {
          if (!gv.find((row) => row.agg1_label === agg1.label)) {
            gv.push({
              annee: gv[0]!.annee!,
              agg1_id: agg1.id || 0,
              agg1_label: agg1.label || '',
              nb: imputation,
            });
          }
        });
        return;
      }
    });

    query.returnedRows = Object.values(grouped).flat();
  } else {
    const grouped2 = mapValues(grouped, (_gv) =>
      groupBy(_gv, { key: 'period' })
    );
    // 2 levels flatten array: first level is the year, second level is the period
    const imputed = Object.values(grouped2).map((gvperyear) =>
      Object.values(gvperyear).map((gv) => {
        if (!gv.length) return [];

        if (gv.length !== (_agg1vls ?? []).length) {
          _agg1vls?.forEach((agg1) => {
            if (!gv.find((row) => row.agg1_label === agg1.label)) {
              gv.push({
                annee: gv[0]!.annee!,
                period: gv[0]!.period!,
                agg1_id: agg1.id || 0,
                agg1_label: agg1.label || '',
                nb: imputation,
              });
            }
          });
        }

        return gv;
      })
    );

    query.returnedRows = imputed.flat(2);
  }
}

export default function resToDataSeries(
  query: Query,
  type:
    | 'multiline'
    | 'line'
    | 'bar'
    | 'bar100'
    | 'donut'
    | 'diverging'
    | 'scatter'
    | 'multibar' = 'donut',
  roundDecimals = 0,
  ignorePourcentage = false,
  imputation: number | null = 0
) {
  const datas: number[] = query.returnedRows?.map((row) =>
    typeof row.nb === 'number' ? round(row.nb, roundDecimals) : row.nb
  ) as number[];
  switch (type) {
    case 'multiline': {
      const labels = query.returnedRows
        ?.map((row) => row.agg1_label)
        .filter(
          (value, index, array) => array.indexOf(value) === index
        ) as string[];

      // @ts-expect-error imputation can be null in case we dont wanna impute missing values
      // impute missing values
      impute(query, imputation);

      const series = labels.map((label) => ({
        name: label,
        data: query.returnedRows
          ?.filter((row) => row.agg1_label === label)
          .map((row) => (ignorePourcentage ? row.nb : row.pourcentage)),
      })) as ApexAxisChartSeries;

      return series;
    }
    case 'bar100': {
      // excepted value in array: nb, agg2_label, agg1_label,
      const agg1_label = query.returnedRows
        ?.map((row) => row.agg1_label)
        .filter(
          (value, index, array) => array.indexOf(value) === index
        ) as string[];
      const agg2Labels = query.returnedRows
        ?.map((row) => row.agg2_label)
        .filter(
          (value, index, array) => array.indexOf(value) === index
        ) as string[];
      const multiSeries = agg2Labels.map((label) => ({
        name: label,
        data: agg1_label.map((labelAgg1) => {
          const row = query.returnedRows?.find(
            (row) => row.agg1_label === labelAgg1 && row.agg2_label === label
          );
          return row?.nb ?? 0;
        }), //query?.returnedRows?.filter((row) => row.agg2_label === label).map((row) => row.nb),
      })) as ApexAxisChartSeries;
      return multiSeries;
    }
    case 'diverging': {
      // group by
      const sexes = query.returnedRows
        ?.map((row) => row.agg1_label)
        .filter(
          (value, index, array) => array.indexOf(value) === index
        ) as string[];
      const divergingSeries = sexes.map((sexe, index) => ({
        name: sexe,
        data: query.returnedRows
          ?.filter((row) => row.agg1_label === sexe)
          .map((row) =>
            index === 0 ? row.nb : row.nb === 0 ? -0.00000001 : -row.nb!
          ),
      })) as ApexAxisChartSeries;
      return divergingSeries;
    }
    case 'donut':
      return datas;
    case 'multibar': {
      const _labels = query.returnedRows
        ?.map((row) => row.agg1_label)
        .filter(
          (value, index, array) => array.indexOf(value) === index
        ) as string[];

      const res = _labels.map((label) => ({
        name: label,
        data: query.returnedRows
          ?.filter((row) => row.agg1_label === label)
          .map((row) => row.nb),
      }));

      return res.map((obj) => {
        const total = (obj.data ?? []).reduce(
          (acc, curr) =>
            typeof acc === 'number' && typeof curr === 'number'
              ? acc + curr
              : 0,
          0
        ) as number;
        return {
          name: obj.name,
          data: obj.data?.map((row) =>
            round(((row as number) / total) * 100, roundDecimals)
          ),
        };
      }) as ApexAxisChartSeries;
    }

    case 'bar':
    case 'line':
    default:
      return [{ data: datas, name: 'Total' }] as ApexAxisChartSeries;
  }
}
