import ActiveFiltersSection from '@/components/ActiveFiltersSection';
import GlobalLoader from '@/components/GlobalLoader';
import MultiSelect from '@/components/MultiSelect';
import Select from '@/components/Select';
import TitleSection from '@/components/TitleSection';
import type {
  ActiveFilters,
  FilterSelect,
  IdLabelObject,
  SortField,
} from '@/types';
import clsx from 'clsx';
import type { Query } from 'database/bigQueryHelper';
import type { FilterAttributes } from 'database/queryHelper';
import { addDataTable } from 'lib/functionHelper';
import { numberToString } from 'lib/numberToString';
import { get, orderBy } from 'lodash';
import { useEffect, useState } from 'react';
import ApexRender from 'ui/ApexRender';
import IconCard from 'ui/IconCard';
import { barOptions } from 'ui/apex/apexHelper';
import resToDataSeries from 'ui/apex/resToSeries';

type TemplatePart1Props = {
  filters: FilterSelect[];
  kpiData: Query;
  initialKpiData: Query;
  updateKpiData: (
    filters: ActiveFilters,
    // eslint-disable-next-line @typescript-eslint/ban-types
    forced?: { before?: Function; after?: Function; inbetweencb?: Function }
  ) => Promise<void>;
  commonSort?: IdLabelObject;
  setSort: (sort: IdLabelObject, section?: string) => void;
  sortField: SortField;
  activeFilters: ActiveFilters;
  templateOptions: {
    source?: string;
    sortOptions: IdLabelObject[];
    graphTitle: string; // 'Répartition des entreprises par branche'
    graphColors?: string[]; // ['#EC6608']
    bqTable: string; // 'opco2i_etab_ent'
    exportMesureFieldname: string; // "Nombre d'entreprises"
    extraOption?: (filter: FilterSelect) => IdLabelObject | undefined;
    iconCard?: {
      bgClass?: string; // Icon background color
      icon?: string; // Icon name
    };
    roundInterindustrie?: boolean;
  };
};

export default function TemplatePart1({
  filters,
  kpiData,
  initialKpiData,
  updateKpiData,
  commonSort,
  setSort,
  sortField,
  activeFilters,
  templateOptions,
}: TemplatePart1Props) {
  const [_kpiheight, setKpiHeight] = useState(2000);
  const [_loading, setLoading] = useState(false);
  const [_sort, _setLocalSort] = useState(
    commonSort ?? templateOptions.sortOptions[0]
  );
  const [_titlepart, setTitlePart] = useState('alternants');

  const handlers = {
    _fbranche: {
      update: async (values: IdLabelObject[], filterFieldid: string) => {
        setLoading(true);
        const _filters = { ...activeFilters };
        _filters[filterFieldid] = values;

        // check duplicate values
        const filtersMap = new Map(Object.entries(_filters));
        const uniqueFilters = Object.fromEntries(filtersMap.entries());

        // Old code
        // setLoading(true);
        // const _filters: ActiveFilters = clone(activeFilters);
        // _filters[filterFieldid] = values;

        // // check duplicate values
        // const uniqueFilters = Object.keys(_filters).reduce<Record<string, any>>(
        //   (acc, key) => {
        //     if (!(isArray(_filters) && isEmpty(_filters[key]))) {
        //       acc[key] = _filters[key];
        //     }
        //     return acc;
        //   },
        //   {}
        // );

        await updateKpiData(uniqueFilters, {
          after: () => {
            setLoading(false);
          },
        });
      },

      delete: (filter: IdLabelObject, filterFieldid?: string) => {
        const _fid = filterFieldid ?? 'num_branche';
        const _filters = { ...activeFilters };
        const filtersMap = new Map(Object.entries(_filters));
        const value = filtersMap.get(_fid);

        if (value === undefined) return;

        setLoading(true);
        filtersMap.set(
          _fid,
          value.filter((v) => v.id !== filter.id)
        );
        const uniqueFilters = Object.fromEntries(filtersMap.entries());

        // Old code
        // const _fid = filterFieldid ?? 'num_branche';
        // const _filters: ActiveFilters = clone(activeFilters);
        // _filters[_fid] = (_filters[_fid] as IdLabelObject[]).filter((v) => v.id !== filter.id);

        // setLoading(true);
        // // check duplicate values
        // const uniqueFilters = Object.keys(_filters).reduce<Record<string, any>>(
        //   (acc, key) => {
        //     if (!(isArray(_filters) && isEmpty(_filters[key]))) {
        //       acc[key] = _filters[key];
        //     }
        //     return acc;
        //   },
        //   {}
        // );

        void updateKpiData(uniqueFilters, {
          after: () => {
            setLoading(false);
          },
        });
      },

      reset: () => {
        setLoading(true);
        void updateKpiData(
          {},
          {
            after: () => {
              setLoading(false);
            },
          }
        );
      },
    },
    _sort: (_newsort: IdLabelObject) => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      _setLocalSort(_newsort);
      setSort(_newsort);
    },
    _render: (component: JSX.Element): JSX.Element => {
      return _loading ? <GlobalLoader></GlobalLoader> : component;
    },
    _setTitlePart: () => {
      if (
        activeFilters.code_dispositif &&
        activeFilters.code_dispositif.length > 0
      ) {
        let _dispositif = activeFilters.code_dispositif[0]?.id;

        // since "code_dispositif" have only 2 values in database "apprentissage" and "cpro", the combo = "alternants", so we need to check if there is more than 1 value
        if (activeFilters.code_dispositif.length > 1) {
          _dispositif = '_all';
        }

        switch (_dispositif) {
          case 'apprentissage':
            setTitlePart('apprentis');
            break;
          case 'cpro':
            setTitlePart('contrats de professionnalisation');
            break;
          case '_all':
            setTitlePart('alternants');
            break;
        }
      } else {
        setTitlePart('alternants');
      }
    },
    _getInterindustrieValue: () => {
      return (
        Math.round(initialKpiData.returnedRows?.reduce(
          (prev, curr) =>
            prev + (curr.nb as number),
          0
        ) as number / 10) * 10 || NaN
      );
    },
  };

  const applySort = (_kpiData: Query) => {
    _kpiData.returnedRows = orderBy(
      _kpiData.returnedRows,
      [sortField],
      [sortField === 'agg1_label' ? 'asc' : 'desc']
    ).map((row) => ({ ...row, total: row.nb || 0 }));

    // specific sort for "Etablissement sans cc" (num_branche: 999) which should be always at the end
    const _etabSansCC = _kpiData.returnedRows.filter(
      (row) => row.num_branche && row.num_branche === 999
    );
    if (_etabSansCC && _etabSansCC.length > 0) {
      _kpiData.returnedRows = _kpiData.returnedRows.filter(
        (row) => row.num_branche && row.num_branche !== 999
      );
      _kpiData.returnedRows.push(..._etabSansCC);
    }

    return _kpiData;
  };

  useEffect(() => {
    handlers._setTitlePart();
    if (kpiData.returnedRows) {
      const _kpiheightUnitPerRow = 70; // 70px per row
      const nbRows = kpiData.returnedRows.length;
      if (nbRows === 1) {
        setKpiHeight(140);
        return;
      }
      setKpiHeight(_kpiheightUnitPerRow * nbRows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kpiData]);

  useEffect(() => {
    const _found =
      commonSort &&
      templateOptions.sortOptions.find((s) => s.id === commonSort.id);
    if (_found) {
      _setLocalSort(_found);
    } else {
      _setLocalSort(templateOptions.sortOptions[0]);
    }
  }, [commonSort, templateOptions.sortOptions]);

  const _filterToExport: FilterAttributes[] = Object.keys(activeFilters).reduce(
    (acc: FilterAttributes[], key: string) => {
      if (!(Array.isArray(activeFilters) && activeFilters[key]?.length === 0)) {
        acc.push({
          id: key,
          label: key,
          ref: key,
          value: activeFilters[key]?.map((v: IdLabelObject) => v.id),
        });
      }
      return acc;
    },
    []
  );

  const _interindusValue = handlers._getInterindustrieValue();

  const specificConditions = {
    hideAsterisk:
      activeFilters.num_branche &&
      activeFilters.num_branche.length > 0 &&
      !activeFilters.num_branche.find(
        (v: IdLabelObject) => v.id.toString() === '41'
      ),
  };

  return handlers._render(
    <>
      <div
        className='relative w-full p-4 bg-white rounded-b-lg xl:rounded-tl-lg px-2 mb-4 shadow-md'
        id='filter-container'
      >
        <div className='grid grid-cols-5'>
          <div className='col-span-5 xl:col-span-2'>
            <p className='leading-6 text-left font-semibold text-[#06396E] text-sm 2xl:text-base pt-4 px-6'>
              Je filtre par
            </p>
            <div
              id='filter-list'
              className={clsx(
                'w-full grid',
                filters.length > 1 ? 'grid-cols-2' : 'grid-cols-1'
              )}
            >
              {filters.map((filter: FilterSelect) =>
                filter.multiple ? (
                  <MultiSelect
                    key={filter.fieldid}
                    title={''}
                    options={filter.values ?? []}
                    selected={activeFilters[filter.fieldid] ?? []}
                    extraOption={
                      templateOptions.extraOption
                        ? templateOptions.extraOption(filter)
                        : undefined
                    }
                    disabled={false}
                    inputValue={`${(activeFilters[filter.fieldid] ?? []).length
                      } filtres sélectionnés`}
                    placeholder={filter.placeholder}
                    asyncSetValues={async (values) => {
                      await handlers._fbranche.update(values, filter.fieldid);
                    }}
                    widthTailwind='w-auto mx-5 py-3 min-w-[100px]'
                    inputColor='bg-gray-100'
                  />
                ) : (
                  <Select
                    key={filter.fieldid}
                    title={''}
                    options={filter.values ?? []}
                    coloredInput
                    selectedVal={get(activeFilters[filter.fieldid] ?? [], '0')}
                    extraOption={
                      templateOptions.extraOption
                        ? templateOptions.extraOption(filter)
                        : undefined
                    }
                    disabled={false}
                    placeholder={filter.placeholder}
                    asyncSetValue={async (value: Required<IdLabelObject>) => {
                      await handlers._fbranche.update([value], filter.fieldid);
                    }}
                    widthTailwind='w-auto mx-5 py-3 min-w-[100px]'
                    inputColor='bg-gray-100'
                  />
                )
              )}
            </div>
          </div>

          <div className='col-span-5 xl:col-span-3'>
            <p className='leading-6 text-left font-semibold text-[#06396E] text-sm 2xl:text-base pt-4 px-6'>
              Je trie par
            </p>
            <div id='sort-list' className='relative mx-5'>
              <Select
                options={templateOptions.sortOptions}
                setValue={(_v: IdLabelObject) => {
                  handlers._sort(_v);
                }}
                selectedVal={_sort}
                widthTailwind='w-auto py-3 min-w-[100px]'
                inputColor='bg-gray-100'
              />
            </div>
          </div>
        </div>

        <div id='active-filter-list' className='relative mx-5'>
          <ActiveFiltersSection
            filters={activeFilters}
            handleRemoveFilter={handlers._fbranche.delete}
            handleResetFilters={handlers._fbranche.reset}
          />
        </div>
      </div>
      <div className=' mt-[4.25rem]' />
      <TitleSection
        title={templateOptions.graphTitle
          .replace('${mesure}', _titlepart)
          .replace('*', specificConditions.hideAsterisk ? '' : '*')}
        bqTable={templateOptions.bqTable}
        exportData={{ csv: true, png: true, table: false }}
        table={{
          filterToExport: _filterToExport,
          data: addDataTable(applySort(kpiData).returnedRows ?? [], 'nb'),
          columns: {
            agg1_label: 'Branche professionnelle',
            nb: templateOptions.exportMesureFieldname.replace(
              '${mesure}',
              _titlepart
            ),
          },
        }}
        subSource
        height={`h-[${_kpiheight || '350'}px]`}
      >
        <a
          className='relative ml-4  mb-3 items-start flex flex-row justify-start w-fit'
          href='https://opco2i-iframe.kyu.fr/pdf/OPCO 2i- Note méthodologique portraits de Branche.pdf'
          target='_blank'
          rel='noreferrer'
        >
          <p className='text-left text-[10px] font-normal text-black py-1 px-2 rounded-md bg-gray-100'>
            {(!!templateOptions.source && templateOptions.source) ||
              'Sources : INSEE et OPCO 2i'}
          </p>
        </a>
        {templateOptions.graphTitle
          .replace('${mesure}', _titlepart)
          .endsWith('*') && !specificConditions.hideAsterisk ? (
          <p className='text-left text-[10px] text-gray-500 ml-6 -mt-1'>
            * hors branche Industries électriques et gazières dont les données
            datent de 2023
          </p>
        ) : null}
        {/* Interindustrie Card */}
        <div className='relative flex justify-center items-center'>
          <IconCard
            values={[
              {
                title: 'Total interindustrie',
                value: numberToString(_interindusValue),
                icon: `/icons/${templateOptions.iconCard?.icon || 'sigma-orange.svg'}`,
              },
            ]}
            fontColor={`text-black`}
            titleColor={`text-black`}
            bgColor={templateOptions.iconCard?.bgClass ?? 'bg-blue-gray-200'}
            noBorder
          ></IconCard>
        </div>

        <div id='kpi-container' className='relative w-full -ml-3 px-4'>
          <ApexRender
            options={barOptions({
              labels:
                applySort(kpiData).returnedRows?.map(
                  (row) => row.agg1_label?.toString() ?? 'Autres'
                ) ?? ([''] as string[]),
              // Don't use hard-coded colors: create a color palette in tailwind.config.js
              colors: templateOptions.graphColors ?? ['#EC6608'],
              numberFormat: true,
              showRepartition: true,
              horizontal: true,
              data: applySort(kpiData).returnedRows,
              yAxisMaxWidth: 200,
              yAxisMinWidth: 200,
              lengthToWrap: 20,
              hideGrid: true,
              offsetX: 45,
              cancelAnimations: true,
              barHeight: '70%',
            })}
            series={resToDataSeries(applySort(kpiData), 'bar')}
            type={'bar'}
            style={{ width: '100%', height: `${_kpiheight}px` }}
          />
        </div>
      </TitleSection>
    </>
  );
}
