import ActiveFiltersSection from '@/components/ActiveFiltersSection';
import GlobalLoader from '@/components/GlobalLoader';
import MultiSelect from '@/components/MultiSelect';
import Select from '@/components/Select';
import TitleSection from '@/components/TitleSection';
import type {
  ActiveFilters,
  FilterSelect,
  IdLabelObject,
  SortField,
} from '@/types';
import type { Query, ReturnedRow } from 'database/bigQueryHelper';
import { groupBy } from 'database/generics';
import type { FilterAttributes } from 'database/queryHelper';
import { addDataTable } from 'lib/functionHelper';
import { clone, get, isArray, isEmpty, orderBy } from 'lodash';
import { useEffect, useState } from 'react';
import ApexRender from 'ui/ApexRender';
import { bar100Options, barOptions } from 'ui/apex/apexHelper';
import resToDataSeries from 'ui/apex/resToSeries';

type TemplatePart2Props = {
  filters: FilterSelect[];
  kpiData: Query;
  initialKpiData: Query;
  updateKpiData: (
    filters: ActiveFilters,
    forced?: { before?: Function; after?: Function; inbetweencb?: Function }
  ) => Promise<void>;
  setSort: (sort: IdLabelObject, section?: string) => void;
  commonSort?: IdLabelObject;
  sortField: SortField;
  activeFilters: ActiveFilters;
  templateOptions: {
    source?: string;
    sections: IdLabelObject[];
    sortOptions: IdLabelObject[];
    graphTitle: string; // 'Répartition des entreprises par branche'
    graphColors?: string[];
    navigationColor?: string;
    bqTable: string; // 'opco2i_etab_ent'
    exportMesureFieldname: string; // "Nombre d'entreprises"
    exportAgg2Fieldname: string; // "Taille d'entreprise"
    agg2Field: string; // 'num_taille_entreprise'
    extraOption?: (filter: FilterSelect) => IdLabelObject | undefined;
  };
  secretStatText?: string;
};

const renderSection = (
  section: string,
  _kpiData: Query,
  _initialKpiData: Query,
  _kpiheight: number,
  sortField: SortField,
  secretStatText?: string,
  graphColors?: string[],
  forcedSingleGraphColor?: string
) => {
  // apply sort
  _kpiData.returnedRows = orderBy(_kpiData.returnedRows, [sortField], ['desc']);

  // specific sort for "Etablissement sans cc" (num_branche: 999) which should be always at the end
  const _etabSansCC = _kpiData.returnedRows.filter(
    (row) => row.num_branche && row.num_branche === 999
  );
  if (_etabSansCC && _etabSansCC.length > 0) {
    _kpiData.returnedRows = _kpiData.returnedRows.filter(
      (row) => row.num_branche && row.num_branche !== 999
    );
    _kpiData.returnedRows.push(..._etabSansCC);
  }

  const getInterindustrieRows = (_q: Query, section?: string) => {
    const _c = clone(_q);
    _c.returnedRows = _c.returnedRows?.map((d: any) => ({
      ...d,
      num_branche: 9999,
      agg1_label: 'Interindustrie',
    }));

    // remove duplicates
    _c.returnedRows = _c.returnedRows?.reduce(
      (acc: ReturnedRow[], curr: ReturnedRow) => {
        const found = acc.find((d) => d.agg2_label === curr.agg2_label);
        if (found) {
          if (section) {
            (found.absolute as number) += curr.absolute as number;
            (found.total as number) += curr.total as number;
          } else {
            (found.nb as number) += curr.nb as number;
          }
        } else {
          acc.push(curr);
        }
        return acc;
      },
      []
    );

    if (section) {
      _c.returnedRows = _c.returnedRows?.map((d: any) => ({
        ...d,
        nb: (d.absolute / d.total) * 100,
      }));
    }

    return _c;
  };

  const filterSecretStat = (_q: Query) => {
    const _c = clone(_q);
    const allLabels = _c.returnedRows?.map(
      (d: ReturnedRow) => d.agg1_label
    ) as string[];
    const secretLabels = allLabels
      .filter((label: string) => {
        const found = _c.returnedRows?.filter(
          (d: ReturnedRow) => d.agg1_label === label
        );
        if (found) {
          const total = found.reduce(
            (acc, curr) => acc + (curr.total as number),
            0
          );
          if (total === null || total === undefined || total === 0) {
            return true;
          }
        }
        return false;
      })
      .filter((value, index, array) => array.indexOf(value) === index);
    _c.returnedRows = _c.returnedRows?.filter(
      (d: ReturnedRow) => !secretLabels.includes(d.agg1_label as string)
    );
    return _c;
  };

  const getSecretStat = (_q: Query, _i?: Query) => {
    const _c = clone(_q);
    const allLabels = _c.returnedRows?.map(
      (d: ReturnedRow) => d.agg1_label
    ) as string[];
    const secretLabels = allLabels
      .filter((label: string) => {
        const found = (_i || _c).returnedRows?.filter(
          (d: ReturnedRow) => d.agg1_label === label
        );
        if (found) {
          const total = found.reduce(
            (acc, curr) => acc + (curr.total as number),
            0
          );
          if (total === null || total === undefined || total === 0) {
            return true;
          }
        }
        return false;
      })
      .filter((value, index, array) => array.indexOf(value) === index);

    _c.returnedRows = _c.returnedRows?.filter((d: ReturnedRow) =>
      secretLabels.includes(d.agg1_label as string)
    );
    return _c;
  };

  const secret = getSecretStat(_kpiData, _initialKpiData);
  const withoutSecret = filterSecretStat(_kpiData);

  const secretLabel = secret.returnedRows
    ?.map((d: ReturnedRow) => d.agg1_label)
    .filter(
      (value, index, array) => array.indexOf(value) === index
    ) as string[];

  switch (section) {
    case '0':
      return (
        <div id='kpi-container1' className='relative w-full -ml-3 px-4'>
          <div className=''>
            <ApexRender
              options={bar100Options({
                labels: getInterindustrieRows(_initialKpiData)
                  .returnedRows?.map((d: ReturnedRow) => d.agg1_label)
                  .filter(
                    (value, index, array) => array.indexOf(value) === index
                  ) as string[],
                colors: graphColors || [
                  '#FCC198',
                  '#FAA265',
                  '#EC6608',
                  '#B14D06',
                  '#c5c6d0',
                ],
                horizontal: true,
                lengthToWrap: 20,
                barHeight: '100%',
                yAxisMaxWidth: 200,
                yAxisMinWidth: 200,
                hideLegend: false,
                legendPosition: 'top',
                cancelAnimations: true,
                hideXaxis: true,
              })}
              series={resToDataSeries(
                getInterindustrieRows(_initialKpiData),
                'bar100'
              )}
              type={'bar'}
              style={{ width: '100%', height: `140px` }}
            />
          </div>

          {withoutSecret.returnedRows?.length! > 0 ||
          (withoutSecret.returnedRows?.length! === 0 &&
            secret.returnedRows?.length! === 0) ? (
            <ApexRender
              options={bar100Options({
                labels: withoutSecret.returnedRows
                  ?.map((d: ReturnedRow) => d.agg1_label)
                  .filter(
                    (value, index, array) => array.indexOf(value) === index
                  ) as string[],
                colors: graphColors || [
                  '#FCC198',
                  '#FAA265',
                  '#EC6608',
                  '#B14D06',
                  '#c5c6d0',
                ],
                horizontal: true,
                yAxisMaxWidth: 200,
                yAxisMinWidth: 200,
                lengthToWrap: 20,
                barHeight:
                  (withoutSecret.returnedRows?.length || 2) > 2
                    ? '70%'
                    : '100%',
                // secretStatText: secretStatText,
                cancelAnimations: true,
                hideLegend: true,
                hideXaxis: secret.returnedRows?.length! > 0 ? true : false,
              })}
              series={resToDataSeries(withoutSecret, 'bar100')}
              type={'bar'}
              style={{ width: '100%', height: `${_kpiheight}px` }}
            />
          ) : null}

          <div className='mt-[-24px]'>
            {secretStatText && secret.returnedRows?.length! > 0 ? (
              <ApexRender
                options={bar100Options({
                  labels: secretLabel,
                  colors: graphColors || [
                    '#FCC198',
                    '#FAA265',
                    '#EC6608',
                    '#B14D06',
                    '#c5c6d0',
                  ],
                  horizontal: true,
                  yAxisMaxWidth: 200,
                  yAxisMinWidth: 200,
                  lengthToWrap: 20,
                  barHeight: '40%',
                  secretStatText: secretStatText,
                  cancelAnimations: true,
                  hideLegend: true,
                })}
                series={resToDataSeries(secret, 'bar100')}
                type={'bar'}
                style={{
                  width: '100%',
                  height: `${secretLabel.length > 1 ? secretLabel.length * 70 : 140}px`,
                }}
              />
            ) : null}
          </div>
        </div>
      );
    case '1':
    case '2':
    case '3':
    case '4':
    case '5':
    case '6':
    case '10':
    case '11':
    case '50':
    case '999':
    case '250':
    case '300':
      return (
        <div id='kpi-container2' className='relative w-full -ml-3 px-4'>
          <div className=''>
            <ApexRender
              options={barOptions({
                labels:
                  getInterindustrieRows(
                    _initialKpiData,
                    section
                  ).returnedRows?.map(
                    (row: any) => row.agg1_label?.toString() || 'Autres'
                  ) || ([''] as string[]),
                colors: forcedSingleGraphColor
                  ? [forcedSingleGraphColor]
                  : graphColors
                    ? graphColors.length > 2
                      ? [graphColors[2]!]
                      : graphColors
                    : ['#EC6608'],
                numberFormat: true,
                horizontal: true,
                data: getInterindustrieRows(_initialKpiData, section)
                  .returnedRows,
                lengthToWrap: 20,
                barHeight: '100%',
                yAxisMaxWidth: 200,
                yAxisMinWidth: 200,
                hideGrid: true,
                suffix: '%',
                showAbsolute: true,
                offsetX: 45,
                cancelAnimations: true,
                maxX: 100,
                minX: 0,
              })}
              series={resToDataSeries(
                getInterindustrieRows(_initialKpiData, section),
                'bar'
              )}
              type={'bar'}
              style={{ width: '100%', height: `140px` }}
            />
          </div>
          {withoutSecret.returnedRows?.length! > 0 ||
          (withoutSecret.returnedRows?.length! === 0 &&
            secret.returnedRows?.length! === 0) ? (
            <ApexRender
              options={barOptions({
                labels:
                  withoutSecret.returnedRows?.map(
                    (row: any) => row.agg1_label?.toString() || 'Autres'
                  ) || ([''] as string[]),
                colors: forcedSingleGraphColor
                  ? [forcedSingleGraphColor]
                  : graphColors
                    ? graphColors.length > 2
                      ? [graphColors[2]!]
                      : graphColors
                    : ['#EC6608'],
                numberFormat: true,
                horizontal: true,
                data: withoutSecret.returnedRows,
                yAxisMaxWidth: 200,
                yAxisMinWidth: 200,
                lengthToWrap: 20,
                barHeight:
                  (withoutSecret.returnedRows?.length || 2) > 2
                    ? '70%'
                    : '100%',
                hideGrid: true,
                suffix: '%',
                showAbsolute: true,
                offsetX: 45,
                cancelAnimations: true,
                maxX: 100,
                minX: 0,
              })}
              series={resToDataSeries(withoutSecret, 'bar')}
              type={'bar'}
              style={{ width: '100%', height: `${_kpiheight}px` }}
            />
          ) : null}

          <div className='mt-[-24px]'>
            {secret.returnedRows?.length! > 0 ? (
              <ApexRender
                options={barOptions({
                  labels: secretLabel,
                  colors: forcedSingleGraphColor
                    ? [forcedSingleGraphColor]
                    : graphColors
                      ? graphColors.length > 2
                        ? [graphColors[2]!]
                        : graphColors
                      : ['#EC6608'],
                  numberFormat: true,
                  horizontal: true,
                  data: secret.returnedRows,
                  yAxisMaxWidth: 200,
                  yAxisMinWidth: 200,
                  lengthToWrap: 20,
                  barHeight: '40%',
                  hideGrid: true,
                  suffix: '%',
                  showAbsolute: true,
                  offsetX: 45,
                  cancelAnimations: true,
                  maxX: 100,
                  minX: 0,
                  secretStatText: secretStatText,
                })}
                series={resToDataSeries(secret, 'bar')}
                type={'bar'}
                style={{
                  width: '100%',
                  height: `${secretLabel.length > 1 ? secretLabel.length * 70 : 140}px`,
                }}
              />
            ) : null}
          </div>
        </div>
      );
    default:
      return (
        <div id='kpi-container' className='relative w-full -ml-3 px-4'></div>
      );
  }
};

export default function TemplatePart2({
  filters,
  kpiData,
  initialKpiData,
  updateKpiData,
  setSort,
  commonSort,
  sortField,
  activeFilters,
  templateOptions,
  secretStatText,
}: TemplatePart2Props) {
  const [_kpiheight, setKpiHeight] = useState<number>(2000);
  const [_loading, setLoading] = useState<boolean>(false);
  const [_sort, _setLocalSort] = useState<IdLabelObject | undefined>(
    commonSort || templateOptions.sortOptions[0]
  );
  const [_section, setSection] = useState<string>('0');
  const [_titlepart, setTitlePart] = useState<string>('alternants');
  const bgColor = 'bg-[' + (templateOptions.navigationColor || '#D6075C') + ']';

  const handlers = {
    _fbranche: {
      update: async (values: IdLabelObject[], filterFieldid: string) => {
        setLoading(true);
        const _filters: ActiveFilters = clone(activeFilters);
        _filters[filterFieldid] = values;

        // check duplicate values
        const uniqueFilters = Object.keys(_filters).reduce<Record<string, any>>(
          (acc, key) => {
            if (!(isArray(_filters) && isEmpty(_filters[key]))) {
              acc[key] = _filters[key];
            }
            return acc;
          },
          {}
        );

        await updateKpiData(uniqueFilters, {
          after: () => {
            setLoading(false);
          },
        });
      },
      delete: async (filter: IdLabelObject, filterFieldid?: string) => {
        const _fid = filterFieldid || 'num_branche';
        const _filters: ActiveFilters = clone(activeFilters);
        _filters[_fid] = (_filters[_fid] as IdLabelObject[]).filter(
          (v) => v.id !== filter.id
        );

        setLoading(true);
        // check duplicate values
        const uniqueFilters = Object.keys(_filters).reduce<Record<string, any>>(
          (acc, key) => {
            if (!(isArray(_filters) && isEmpty(_filters[key]))) {
              acc[key] = _filters[key];
            }
            return acc;
          },
          {}
        );
        await updateKpiData(uniqueFilters, {
          after: () => {
            setLoading(false);
          },
        });
      },
      reset: async () => {
        setLoading(true);
        await updateKpiData(
          {},
          {
            after: () => {
              setLoading(false);
            },
          }
        );
        setLoading(false);
      },
    },
    _sort: (_newsort: IdLabelObject | undefined, forcedSection?: string) => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      _setLocalSort(_newsort);
      _newsort && setSort(_newsort, forcedSection || _section);
    },
    _render: (component: JSX.Element): JSX.Element => {
      return _loading ? (
        <div className='w-full min-h-screen'>
          <GlobalLoader></GlobalLoader>
        </div>
      ) : (
        component
      );
    },
    _setTitlePart: () => {
      if (
        activeFilters.code_dispositif &&
        activeFilters.code_dispositif.length > 0
      ) {
        let _dispositif = activeFilters.code_dispositif[0]?.id;

        // since "code_dispositif" have only 2 values in database "apprentissage" and "cpro", the combo = "alternants", so we need to check if there is more than 1 value
        if (activeFilters.code_dispositif.length > 1) {
          _dispositif = '_all';
        }

        switch (_dispositif) {
          case 'apprentissage':
            setTitlePart('apprentis');
            break;
          case 'cpro':
            setTitlePart('contrats de professionnalisation');
            break;
          case '_all':
            setTitlePart('alternants');
            break;
        }
      } else {
        setTitlePart('alternants');
      }
    },
  };
  useEffect(() => {
    handlers._setTitlePart();

    if (kpiData.returnedRows) {
      const _heightUnitPerRow = 70;
      const nbBranches = groupBy(kpiData.returnedRows, { key: 'num_branche' });
      if (nbBranches) {
        const nbRows = Object.keys(nbBranches).length;
        if (nbRows === 1) {
          setKpiHeight(140);
          return;
        }

        setKpiHeight(
          nbRows * (nbRows < 5 ? _heightUnitPerRow * 1.2 : _heightUnitPerRow)
        );
      } else {
        setKpiHeight(500);
      }
    }
  }, [kpiData]);

  useEffect(() => {
    const _found =
      commonSort &&
      templateOptions.sortOptions.find((s) => s.id === commonSort.id);
    if (_found) {
      _setLocalSort(_section === '0' ? templateOptions.sortOptions[0] : _found);
    }
  }, [commonSort]);

  const changeSection = (section: string) => {
    setLoading(true);
    setSection(section);
    if (section === '0' && ['3', '4'].includes(_sort?.id ?? '')) {
      handlers._sort(templateOptions.sortOptions[0]);
    } else {
      handlers._sort(_sort, section);
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const getDataBySection = (section: string, useInitialData?: boolean) => {
    const _kpiData = useInitialData ? initialKpiData : kpiData;

    const sectionData = _kpiData.returnedRows?.filter(
      (row) => get(row, templateOptions.agg2Field) === parseInt(section)
    );
    const rows = section === '0' ? _kpiData.returnedRows : sectionData;
    const data = rows?.map((row) => {
      // get total for each agg1_label (branche)
      const total = _kpiData.returnedRows
        ?.filter((r) => r.agg1_label === row.agg1_label)
        .reduce((acc, r) => acc + (r.nb as number), 0);
      return {
        ...row,
        nb:
          section === '0'
            ? row.nb
            : ((row.nb as number) / (total || 100)) * 100, // fill nb with repartition in %
        absolute: row.nb as number, // fill absolute with nb
        total: total!,
      } as ReturnedRow;
    });
    return { ..._kpiData, returnedRows: data };
  };

  const _filtredSortOptions =
    _section === '0'
      ? templateOptions.sortOptions.filter((_s, i) => i < 1)
      : templateOptions.sortOptions;

  const _filterToExport: FilterAttributes[] = Object.keys(activeFilters).reduce(
    (acc: FilterAttributes[], key: string) => {
      if (!(isArray(activeFilters) && isEmpty(activeFilters[key]))) {
        acc.push({
          id: key,
          label: key,
          ref: key,
          value: activeFilters[key]?.map((v: IdLabelObject) => v.id),
        });
      }
      return acc;
    },
    []
  );

  const specificConditions = {
    hideAsterisk:
      activeFilters.num_branche &&
      activeFilters.num_branche.length > 0 &&
      !activeFilters.num_branche.find(
        (v: IdLabelObject) => v.id.toString() === '41'
      ),
  };

  return handlers._render(
    <>
      <div
        className='relative w-full p-4 bg-white rounded-b-lg xl:rounded-tl-lg px-2 shadow-md'
        id='filter-container'
      >
        <div className='grid grid-cols-5'>
          <div className='col-span-5 xl:col-span-2'>
            <p className='leading-6 text-left font-semibold text-[#06396E] text-sm 2xl:text-base pt-4 px-6'>
              Je filtre par
            </p>
            <div
              id='filter-list'
              className={`w-full grid grid-cols-${filters.length}`}
            >
              {filters.map((filter: FilterSelect) =>
                filter.multiple ? (
                  <MultiSelect
                    key={filter.fieldid}
                    title={''}
                    options={filter.values || []}
                    selected={activeFilters[filter.fieldid] || []}
                    extraOption={
                      templateOptions.extraOption
                        ? templateOptions.extraOption(filter)
                        : undefined
                    }
                    disabled={false}
                    inputValue={`${
                      (activeFilters[filter.fieldid] || []).length
                    } filtres sélectionnées`}
                    placeholder={filter.placeholder}
                    asyncSetValues={async (values) => {
                      await handlers._fbranche.update(values, filter.fieldid);
                    }}
                    widthTailwind='w-auto mx-5 py-3 min-w-[100px]'
                    inputColor='bg-gray-100'
                  />
                ) : (
                  <Select
                    key={filter.fieldid}
                    title={''}
                    options={filter.values || []}
                    coloredInput
                    selectedVal={get(activeFilters[filter.fieldid] || [], '0')}
                    extraOption={
                      templateOptions.extraOption
                        ? templateOptions.extraOption(filter)
                        : undefined
                    }
                    disabled={false}
                    placeholder={filter.placeholder}
                    asyncSetValue={async (value: Required<IdLabelObject>) => {
                      await handlers._fbranche.update([value], filter.fieldid);
                    }}
                    widthTailwind='w-auto mx-5 py-3 min-w-[100px]'
                    inputColor='bg-gray-100'
                  />
                )
              )}
            </div>
          </div>

          <div className='col-span-5 xl:col-span-3'>
            <p className='leading-6 text-left font-semibold text-[#06396E] text-sm 2xl:text-base pt-4 px-6'>
              Je trie par
            </p>
            <div id='sort-list' className='relative mx-5'>
              <Select
                options={_filtredSortOptions}
                setValue={(_v: IdLabelObject) => {
                  handlers._sort(_v);
                }}
                selectedVal={_sort}
                widthTailwind='w-auto py-3 min-w-[100px]'
                inputColor='bg-gray-100'
              />
            </div>
          </div>
        </div>
        <div id='active-filter-list' className='relative mx-5'>
          <ActiveFiltersSection
            filters={activeFilters}
            handleRemoveFilter={handlers._fbranche.delete}
            handleResetFilters={handlers._fbranche.reset}
          />
        </div>
      </div>
      <div className='relative'>
        <div className='flex flex-1 flex-wrap justify-center items-center mb-4 mt-2 px-2 md:px-2'>
          {templateOptions.sections.map((taille: IdLabelObject) => (
            <button
              key={taille.id}
              className={`text-xs w-[200px] h-[50px] shadow-sm px-4 py-2 rounded-lg mr-2 mt-2 ${
                _section === taille.id
                  ? `hover:bg-white ${
                      bgColor ||
                      'bg-[#D6075C]' ||
                      'bg-[#EC6608]' ||
                      'bg-[#71196F]'
                    } hover:text-[#06396E] text-white font-semibold`
                  : 'hover:bg-white bg-gray-300 text-[#06396E] font-normal hover:font-semibold'
              }`}
              onClick={() => {
                changeSection(taille.id);
              }}
            >
              <span className='text-xs font-semibold'>{taille.label}</span>
            </button>
          ))}
        </div>
      </div>
      <TitleSection
        title={
          templateOptions.graphTitle
            .replace('${mesure}', _titlepart)
            .replace('*', specificConditions.hideAsterisk ? '' : '*') +
          ` ${
            templateOptions.sections.find(
              (section) => _section !== '0' && _section === section.id
            )?.label
              ? `(${templateOptions.sections.find((section) => _section !== '0' && _section === section.id)?.label})`
              : ''
          }`
        }
        bqTable={templateOptions.bqTable}
        exportData={{ csv: true, png: true, table: false }}
        table={{
          filterToExport: _filterToExport,
          data: addDataTable(
            getDataBySection(_section).returnedRows?.map(
              (row) => ({ ...row, nb: row.absolute }) as ReturnedRow
            ) ??
              (kpiData.returnedRows || []),
            'nb'
          ),
          columns: {
            nb: templateOptions.exportMesureFieldname.replace(
              '${mesure}',
              _titlepart
            ),
            agg1_label: 'Branche professionnelle',
            agg2_label: templateOptions.exportAgg2Fieldname,
          },
        }}
        subSource
        height={`h-[${_kpiheight || '350'}px]`}
      >
        <>
          <a
            className='relative ml-4 mb-3 items-start flex flex-row justify-start w-fit'
            href='https://opco2i-iframe.kyu.fr/pdf/OPCO 2i- Note méthodologique portraits de Branche.pdf'
            target='_blank'
            rel='noreferrer'
          >
            <p className='text-left text-[10px] font-normal text-black py-1 px-2 rounded-md bg-gray-100'>
              {(!!templateOptions.source && templateOptions.source) ||
                'Sources : INSEE et OPCO 2i'}
            </p>
          </a>
          {templateOptions.graphTitle
            .replace('${mesure}', _titlepart)
            .endsWith('*') && !specificConditions.hideAsterisk ? (
            <p className='text-left text-[10px] text-gray-500 ml-6 -mt-1'>
              * hors branche Industries électriques et gazières dont les données
              datent de 2023
            </p>
          ) : null}
          {sortField &&
            renderSection(
              _section,
              getDataBySection(_section),
              getDataBySection(_section, true),
              _kpiheight,
              sortField,
              secretStatText,
              templateOptions.graphColors,
              templateOptions.navigationColor
            )}
        </>
      </TitleSection>
    </>
  );
}
